import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { profileLocationUpdateAsync } from '../redux/slices/AuthSlice';
import Select from 'react-select';
import { useEffect } from 'react';
import { locationAllAsync } from '../redux/slices/LocationSlice';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { locationCreateAsync } from '../redux/slices/LocationSlice';
import moment from 'moment';

const MyProfileLocationUpdate = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { auth } = useSelector((state) => state.authReducer);
  const { locations } = useSelector((state) => state.locationReducer);
  const { locationcreate } = useSelector((state) => state.locationReducer);
  const [id] = useState(auth?.id);
  const [radious, setRadious] = useState('');
  const [postTimeFrom, setPostTimeFrom] = useState('');
  const [postTimeTo, setPostTimeTo] = useState('');
  const [locationId, setLocationId] = useState(null);
  const [selectedLocationOption, setSelectedLocationOption] = useState(null);

  useEffect(() => {
    console.log(auth);
    setLocationId(auth.locationId);
    setRadious(auth.radious);
    setPostTimeFrom(new Date(auth.postTimeFrom));
    setPostTimeTo(new Date(auth.postTimeTo));
    const filteredLocationData = locations
      .filter((location) => location.id === auth.locationId)
      .map((location) => ({
        value: location.id.toString(),
        label: location.address
          ? `${location.address} ( ${location.latitude} , ${location.longitude} )`
          : `${location.latitude} , ${location.longitude}`,
      }));
    setSelectedLocationOption(filteredLocationData);
  }, [auth, locations]);

  useEffect(() => {
    if (locationcreate && locationcreate?.id) {
      const filteredLocationData = {
        value: locationcreate.id.toString(),
        label: locationcreate.address
          ? `${locationcreate.address} ( ${locationcreate.latitude} , ${locationcreate.longitude} )`
          : `${locationcreate.latitude} , ${locationcreate.longitude}`,
      };
      setSelectedLocationOption(filteredLocationData);
      setLocationId(locationcreate.id);
    }
  }, [locationcreate]);

  useEffect(() => {
    dispatch(locationAllAsync({ toast }));
  }, [dispatch]);

  const locationOptions = locations.map((location) => ({
    value: location.id.toString(),
    label: location.address
      ? `${location.address} ( ${location.latitude} , ${location.longitude} )`
      : `${location.latitude} , ${location.longitude}`,
  }));

  const handleLocationChange = (selectedLocationOption) => {
    setSelectedLocationOption(selectedLocationOption);
    setLocationId(selectedLocationOption['value']);
  };

  const setPostTimeToFun = (date) => {
    setPostTimeTo(date);
  };
  const setPostTimeFromFun = (date) => {
    setPostTimeFrom(date);
  };

  const profileLocationUpdate = (e) => {
    e.preventDefault();
    const postTimeFromUtc = moment.utc(postTimeFrom).toISOString();
    const postTimeToUtc = moment.utc(postTimeTo).toISOString();

    const profileData = new FormData();
    profileData.append('locationId', locationId);
    profileData.append('radious', radious);
    profileData.append('postTimeFrom', postTimeFromUtc);
    profileData.append('postTimeTo', postTimeToUtc);
    dispatch(profileLocationUpdateAsync({ id, profileData, navigate, toast }));
  };

  const handleLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const utcOffset = new Date().getTimezoneOffset() / -60;
          const locationData = {
            latitude: latitude.toString(),
            longitude: longitude.toString(),
            status: 0,
            utcOffset: utcOffset,
          };
          dispatch(locationCreateAsync({ locationData, navigate, toast }));
        },
        (error) => {
          console.error('Error getting geolocation:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  return (
    <section className="form-container">
      <form action="" method="post" encType="multipart/form-data">
        <h3>Update Location & Time</h3>
        <p>
          <span className="btn" type="button" onClick={handleLocation}>
            <i className="fas fa-map-marker-alt"></i> Click here to give
            permisssion to use geo Location
          </span>
        </p>
        <p>update location</p>
        <Select
          value={selectedLocationOption}
          onChange={handleLocationChange}
          options={locationOptions}
          isClearable
          className="box"
          placeholder="Select location..."
          isSearchable
        />
        <p>update location radious (km)</p>
        <select
          className="box"
          value={radious}
          onChange={(e) => setRadious(parseInt(e.target.value))}
        >
          {[...Array(100).keys()].map((number) => (
            <option key={number + 1} value={number + 1}>
              {number + 1}
            </option>
          ))}
        </select>
        <p>update post time from</p>
        <Datetime
          className="box"
          value={postTimeFrom}
          onChange={setPostTimeFromFun}
          inputProps={{ placeholder: 'Select Date and Time' }}
        />
        <p>update post time to</p>
        <Datetime
          className="box"
          value={postTimeTo}
          onChange={setPostTimeToFun}
          inputProps={{ placeholder: 'Select Date and Time' }}
        />
        <input
          type="submit"
          value="update"
          name="submit"
          className="btn"
          onClick={profileLocationUpdate}
        />
      </form>
    </section>
  );
};

export default MyProfileLocationUpdate;
