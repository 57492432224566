import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { courseAllAsync } from '../redux/slices/CourseSlice';
import moment from 'moment';
import { apiFilepath, filepath } from '../helpers/urlConfig';
import { Link } from 'react-router-dom';

const Courses = () => {
  const dispatch = useDispatch();
  const { courses } = useSelector((state) => state.courseReducer);

  useEffect(() => {
    dispatch(courseAllAsync({ toast }));
  }, [dispatch]);

  return (
    <section className="courses">
      <h1 className="heading">All Courses</h1>

      <div className="box-container">
        {courses &&
          courses.length > 0 &&
          courses.map((course, i) => (
            <Link to={`/course/${course?.slug}`} key={course?.id ? course?.id : i}>
            <div className="box">
              <div className="tutor">
                <img
                  src={
                    course?.belongsToUser?.avatar
                      ? apiFilepath +
                        '/uploads/avatar/' +
                        course?.belongsToUser?.avatar
                      : filepath + '/assets/images/company-logo-1658436134701.png'
                  }
                  alt="avatar"
                  onError={(e) => {
                    e.currentTarget.src = filepath + '/assets/images/user.png';
                  }}
                />
                <div className="info">
                  <h3>
                    {course?.belongsToUser?.name
                      ? course?.belongsToUser?.name
                      : 'User'}
                  </h3>
                  <span>
                    {course?.createdAt
                      ? moment(course?.createdAt).format(
                          'MMMM Do YYYY, h:mm:ss a'
                        )
                      : null}
                  </span>
                </div>
              </div>
              <div className="thumb">
                {course?.file ? (
                  <img
                    src={apiFilepath + '/uploads/course/' + course?.file}
                    alt="course"
                    onError={(e) => {
                      e.currentTarget.src = filepath + '/assets/static/no-image.jpg';
                    }}
                  />
                ) : (
                  <img
                    src={filepath + '/assets/static/no-image.jpg'}
                    alt="course"
                    onError={(e) => {
                      e.currentTarget.src = filepath + '/assets/static/no-image.jpg';
                    }}
                  />
                )}
                <span><i className="fas fa-eye"></i></span>
              </div>
              <h3 className="title">{course?.title ? course?.title : null}</h3>
            </div>
            </Link>
          ))}
      </div>
    </section>
  );
};

export default Courses;
