import './App.css';
import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import Layout from './layouts/Layout';
import Login from './pages/Login';
import AccountVerificationForm from './pages/AccountVerificationForm';
import AccountVerificationLinkCreateForm from './pages/AccountVerificationLinkCreateForm';
import ForgotPasswordEmailForm from './pages/ForgotPasswordEmailForm';
import ForgotPasswordOtpForm from './pages/ForgotPasswordOtpForm';
import ForgotPasswordNewPasswordForm from './pages/ForgotPasswordNewPasswordForm';
import Register from './pages/Register';
// import Dashboard from './pages/Dashboard';
import MyProfileUpdate from './pages/MyProfileUpdate';
import MyPasswordUpdate from './pages/MyPasswordUpdate';
import Error from './pages/Error';
import * as url from './helpers/url';
import ProtectedRoute from './routes/ProtectedRoute';
import MyProfile from './pages/MyProfile';
import MyProfileLocationUpdate from './pages/MyProfileLocationUpdate';
import MyProfilePost from './pages/MyProfilePost';
import MyProfileCourse from './pages/MyProfileCourse';
import PostView from './pages/PostView';
import CourseView from './pages/CourseView';
import MyProfileCourseView from './pages/MyProfileCourseView';
import PostCreate from './pages/PostCreate';
import CourseCreate from './pages/CourseCreate';
import PostEdit from './pages/PostEdit';
import CourseEdit from './pages/CourseEdit';
import Posts from './pages/Posts';
import Courses from './pages/Courses';
import About from './pages/About';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndCondition from './pages/TermsAndCondition';
import Teachers from './pages/Teachers';
import PostAddtoCourse from './pages/PostAddtoCourse';
import PostEdittoCourse from './pages/PostEdittoCourse';

function App() {
  return (
    <BrowserRouter basename={'/'}>
      <Routes>
        <Route exact path="/" element={<Layout />}>
        <Route index name="home" element={<Posts />} /> 
        {/* <Route path={url.dashboard()} name="dashboard" element={<Dashboard />} />  */}
          <Route path={url.about()} name="about" element={<About />} />
          <Route path={url.contact()} name="contact" element={<Contact />} />
          <Route
            path={url.privacyPolicy()}
            name="privacy-policy"
            element={<PrivacyPolicy />}
          />
          <Route
            path={url.termsAndCondition()}
            name="terms-condition"
            element={<TermsAndCondition />}
          />
          <Route path={url.teachers()} name="teachers" element={<Teachers />} />
          <Route path={url.login()} name="login" element={<Login />} />
          <Route path={url.accountVerificationLinkCreate()} name="account-verification-link-create" element={<AccountVerificationLinkCreateForm />} />
          <Route path={url.accountVerification(':token')} name="account-verification" element={<AccountVerificationForm />} />
          <Route path={url.forgotPasswordEmail()} name="forgot-password-email" element={<ForgotPasswordEmailForm />} />
          <Route path={url.forgotPasswordOtp()} name="forgot-password-otp" element={<ForgotPasswordOtpForm />} />
          <Route path={url.forgotPasswordNewPassword()} name="forgot-password-new-password" element={<ForgotPasswordNewPasswordForm />} />
          <Route path={url.register()} name="register" element={<Register />} />
          {/* <Route path={url.posts()} name="posts" element={<Posts />} /> */}
          <Route path={url.courses()} name="courses" element={<Courses />} />
          <Route
            path={url.myProfile()}
            name="my-profile"
            element={
              <ProtectedRoute>
                <MyProfile />
              </ProtectedRoute>
            }
          />
              <Route
            path={url.myProfileLocationUpdate()}
            name="my-profile-location-update"
            element={
              <ProtectedRoute>
                <MyProfileLocationUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            path={url.postView(':slug')}
            name="postview"
            element={<PostView />}
          />
          <Route
            path={url.myProfileUpdate()}
            name="my-profile-update"
            element={
              <ProtectedRoute>
                <MyProfileUpdate />
              </ProtectedRoute>
            }
          />
               <Route
            path={url.myPasswordUpdate()}
            name="my-password-update"
            element={
              <ProtectedRoute>
                <MyPasswordUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            path={url.postCreate()}
            name="post-create"
            element={
              <ProtectedRoute>
                <PostCreate />
              </ProtectedRoute>
            }
          />
          <Route
            path={url.courseCreate()}
            name="course-create"
            element={
              <ProtectedRoute>
                <CourseCreate />
              </ProtectedRoute>
            }
          />
          <Route
            path={url.postEdit(':userId', ':slug')}
            name="post-edit"
            element={
              <ProtectedRoute>
                <PostEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path={url.courseEdit(':userId', ':slug')}
            name="course-edit"
            element={
              <ProtectedRoute>
                <CourseEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path={url.myProfilePost()}
            name="my-profile-post"
            element={
              <ProtectedRoute>
                <MyProfilePost />
              </ProtectedRoute>
            }
          />
          <Route
            path={url.myProfileCourse()}
            name="my-profile-course"
            element={
              <ProtectedRoute>
                <MyProfileCourse />
              </ProtectedRoute>
            }
          />
          <Route
            path={url.postAddtoCourse(':userId', ':slug')}
            name="post-addto-course"
            element={
              <ProtectedRoute>
                <PostAddtoCourse />
              </ProtectedRoute>
            }
          />
          <Route
            path={url.postEdittoCourse(':userId', ':postInCourseId')}
            name="post-editto-course"
            element={
              <ProtectedRoute>
                <PostEdittoCourse />
              </ProtectedRoute>
            }
          />
          <Route
            path={url.myProfileCourseView(':slug')}
            name="my-profile-course-view"
            element={
              <ProtectedRoute>
                <MyProfileCourseView />
              </ProtectedRoute>
            }
          />
          <Route
            path={url.courseView(':slug')}
            name="course-view"
            element={<CourseView />}
          />
          <Route path="*" name="error" element={<Error />}  />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
