import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as apiUrl from '../../helpers/api';

export const tagAllAsync = createAsyncThunk(
  'tag/tagAllAsync',
  async ({ toast }, { rejectedWithValue }) => {
    try {
      const response = await apiUrl.tagAll();
      if (response.data.status) {
        // toast.success(response.data.message);
      } else {
        // toast.error(response.data.message);
      }
      const tagData = response.data.data;
      return { tagData };
    } catch (err) {
      // toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

const tagSlice = createSlice({
  name: 'tag',
  initialState: {
    tags: [],
    error: '',
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // all tag
    builder
      .addCase(tagAllAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(tagAllAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.tags = payload.tagData;
      })
      .addCase(tagAllAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export default tagSlice.reducer;
