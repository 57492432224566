import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { loginAsync } from '../redux/slices/AuthSlice';
import GoogleLoginComponent from '../components/GoogleLoginComponent';
import { locationCreateAsync } from '../redux/slices/LocationSlice';
import * as url from '../helpers/url';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locationcreate } = useSelector((state) => state.locationReducer);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const loginSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email: email,
      password: password,
      locationcreate: locationcreate,
    };
    dispatch(loginAsync({ userData, navigate, toast }));
  };

  const handleLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const utcOffset = new Date().getTimezoneOffset() / -60;
          const locationData = {
            latitude: latitude.toString(),
            longitude: longitude.toString(),
            status: 0,
            utcOffset: utcOffset,
          };
          dispatch(locationCreateAsync({ locationData, navigate, toast }));
        },
        (error) => {
          toast.error('Please turn on GPS');
          console.error('Error getting geolocation:', error);
        }
      );
    } else {
      toast.error('GPS is not supported by this browser');
      console.error('Geolocation is not supported by this browser.');
    }
  };



  return (
    <>
      <section className="form-container">
        <form action="" method="post" encType="multipart/form-data">
          <h3>
            <i
              class="info-icon fas fa-info-circle"
              title="Give permisssion to use geo Location before registration."
            ></i>{' '}
            login now
          </h3>
          <p>
            <span className="btn" type="button" onClick={handleLocation}>
              <i className="fas fa-map-marker-alt"></i> Click here to give
              permisssion to use geo Location
            </span>
          </p>
          <p>
            your email <span>*</span>
          </p>
          <input
            type="email"
            name="email"
            placeholder="enter your email"
            required
            maxLength="50"
            className="box"
            onChange={(e) => setEmail(e.target.value)}
          />
          <p>
            your password <span>*</span>
          </p>
          <input
            type="password"
            name="pass"
            placeholder="enter your password"
            required
            maxLength="20"
            className="box"
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type="submit"
            value="login new"
            name="submit"
            className="btn"
            onClick={loginSubmit}
          />
          <div className="box">
            <GoogleLoginComponent locationcreate={locationcreate} />
          </div>
          <Link to={url.forgotPasswordEmail()}>
            <div className="btn">Forgot Password ?</div>
          </Link>
          <Link to={url.accountVerificationLinkCreate()}>
            <div className="box">Click here to resend account verification link</div>
          </Link>
        </form>
      </section>
    </>
  );
};

export default Login;
