import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as apiUrl from '../../helpers/api';
import * as url from '../../helpers/url';
import { profileUpdateAsync } from './AuthSlice';

export const ProfileCourseAsync = createAsyncThunk(
  'course/ProfileCourseAsync',
  async ({ id, toast }, { rejectedWithValue }) => {
    try {
      const response = await apiUrl.ProfileCourse(id);
      if (response.data.status) {
        // toast.success(response.data.message);
      } else {
        // toast.error(response.data.message);
      }
      const ProfileCoursesData = response.data.data;
      return { ProfileCoursesData };
    } catch (err) {
      // toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

export const courseCreateAsync = createAsyncThunk(
  'course/courseCreateAsync',
  async (
    { id, courseData, dispatch, navigate, toast },
    { rejectedWithValue }
  ) => {
    try {
      const response = await apiUrl.courseCreate(courseData, id);
      if (response.data.status) {
        toast.success(response.data.message);
        dispatch(ProfileCourseAsync({ id, toast }));
        navigate(url.myProfileCourse());
      } else {
        toast.error(response.data.message);
      }
      const courseSubmitData = response.data.data;
      return { courseSubmitData };
    } catch (err) {
      toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

export const courseShowAsync = createAsyncThunk(
  'course/courseShowAsync',
  async ({ slug, toast }, { rejectedWithValue }) => {
    try {
      const response = await apiUrl.courseShow(slug);
      if (response.data.status) {
        // toast.success(response.data.message);
      } else {
        // toast.error(response.data.message);
      }
      const courseShowData = response.data.data;
      return { courseShowData };
    } catch (err) {
      // toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

export const postInCourseShowAsync = createAsyncThunk(
  'course/postInCourseShowAsync',
  async ({ postInCourseId, toast }, { rejectedWithValue }) => {
    try {
      const response = await apiUrl.postInCourseShow(postInCourseId);
      if (response.data.status) {
        // toast.success(response.data.message);
      } else {
        // toast.error(response.data.message);
      }
      const postInCourseShowData = response.data.data;
      return { postInCourseShowData };
    } catch (err) {
      // toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

export const courseUpdateAsync = createAsyncThunk(
  'course/courseUpdateAsync',
  async (
    { id, slug, courseData, dispatch, navigate, toast },
    { rejectedWithValue }
  ) => {
    try {
      const response = await apiUrl.courseUpdate(courseData, id, slug);
      if (response.data.status) {
        toast.success(response.data.message);
        dispatch(ProfileCourseAsync({ id, toast }));
        navigate(url.myProfileCourse());
      } else {
        toast.error(response.data.message);
      }
      const courseUpdateData = response.data.data;
      return { courseUpdateData };
    } catch (err) {
      toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

export const courseAllAsync = createAsyncThunk(
  'course/courseAllAsync',
  async ({ toast }, { rejectedWithValue }) => {
    try {
      const response = await apiUrl.courseAll();
      if (response.data.status) {
        // toast.success(response.data.message);
      } else {
        // toast.error(response.data.message);
      }
      const courseData = response.data.data;
      return { courseData };
    } catch (err) {
      // toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

export const postAddtoCourseAsync = createAsyncThunk(
  'course/postAddtoCourseAsync',
  async (
    { id, courseSlug, courseData, dispatch, navigate, toast },
    { rejectedWithValue }
  ) => {
    try {
      const response = await apiUrl.postAddtoCourse(courseData, id, courseSlug);
      if (response.data.status) {
        toast.success(response.data.message);
        navigate(url.myProfileCourseView(courseSlug));
      } else {
        toast.error(response.data.message);
      }
      const postAddtoCourseData = response.data.data;
      return { postAddtoCourseData };
    } catch (err) {
      toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

export const postEdittoCourseAsync = createAsyncThunk(
  'course/postEdittoCourseAsync',
  async (
    { id, courseSlug, courseData, dispatch, navigate, toast },
    { rejectedWithValue }
  ) => {
    try {
      const response = await apiUrl.postEdittoCourse(courseData, id, courseSlug);
      if (response.data.status) {
        toast.success(response.data.message);
        navigate(url.myProfileCourseView(courseSlug));
      } else {
        toast.error(response.data.message);
      }
      const postEdittoCourseData = response.data.data;
      return { postEdittoCourseData };
    } catch (err) {
      toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

const courseSlice = createSlice({
  name: 'course',
  initialState: {
    course: {},
    courses: [],
    courseCreate: {},
    courseupdate: {},
    profilecourses: [],
    postAddtoCourse: {},
    postEdittoCourse: {},
    postInCourseShow: {},
    error: '',
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    //   course update
    builder
      .addCase(courseAllAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(courseAllAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.courses = payload.courseData;
        console.log('course fetch sucessfully!');
      })
      .addCase(courseAllAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    // course create
    builder
      .addCase(courseCreateAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(courseCreateAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.courseCreate = payload.courseSubmitData;
        console.log('course create sucessfully!');
      })
      .addCase(courseCreateAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    // profile courses
    builder
      .addCase(ProfileCourseAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(ProfileCourseAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.profilecourses = payload.ProfileCoursesData;
        console.log('Profile course fetch sucessfully!');
      })
      .addCase(profileUpdateAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //   course show
    builder
      .addCase(courseShowAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(courseShowAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.course = payload.courseShowData;
        console.log('course show data fetch sucessfully!');
      })
      .addCase(courseShowAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //   post in course show
    builder
      .addCase(postInCourseShowAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(postInCourseShowAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.postInCourse = payload.postInCourseShowData;
        console.log('post in course show data fetch sucessfully!');
      })
      .addCase(postInCourseShowAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //   course update
    builder
      .addCase(courseUpdateAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(courseUpdateAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.courseupdate = payload.courseUpdateData;
        console.log('course updated sucessfully!');
      })
      .addCase(courseUpdateAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //   post add to course
    builder
      .addCase(postAddtoCourseAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(postAddtoCourseAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.postAddtoCourse = payload.postAddtoCourseData;
        console.log('post added to course sucessfully!');
      })
      .addCase(postAddtoCourseAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //   post edit to course
    builder
      .addCase(postEdittoCourseAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(postEdittoCourseAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.postEdittoCourse = payload.postEdittoCourseData;
        console.log('post edited to course sucessfully!');
      })
      .addCase(postEdittoCourseAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export default courseSlice.reducer;
