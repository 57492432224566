import React, { useEffect, useState } from 'react';
import { postShowAsync } from '../redux/slices/PostSlice';
import { postAllRelevantAsync, setPage } from '../redux/slices/PostSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { apiFilepath, filepath } from '../helpers/urlConfig';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import * as url from '../helpers/url';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from 'react-share';
import {
  likeCreateAsync,
  viewCreateAsync,
  likeCountAsync,
} from '../redux/slices/LikeViewSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  commentCreateAsync,
  commentUpdateAsync,
  commentDeleteAsync,
  commentAllAsync,
  setPageComment,
} from '../redux/slices/CommentSlice';

const PostView = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { slug } = useParams();
  const { post } = useSelector((state) => state.postReducer);
  const { likeCount, viewCount, liked } = useSelector(
    (state) => state.likeViewReducer
  );
  const { postRelevantLists, pageCount, hasMore } = useSelector(
    (state) => state.postReducer
  );
  const { commentLists, pageCountComment, hasMoreComment } = useSelector(
    (state) => state.commentReducer
  );
  const { auth } = useSelector((state) => state.authReducer);

  const [shareUrl, setShareUrl] = useState('');
  const [shareTitle, setShareTitle] = useState('');
  const [shareTag, setShareTag] = useState('');
  const [search, setSearch] = useState('');
  const [comment, setComment] = useState('');
  const [editComment, setEditComment] = useState('');
  const [visibleEditFormId, setVisibleEditFormId] = useState();

  useEffect(() => {
    var page = 1;
    dispatch(setPage(page));
    if (auth && auth?.id) {
      const authid = auth?.id;
      dispatch(
        postAllRelevantAsync({
          authid,
          page,
          search,
          toast,
          navigate,
          dispatch,
        })
      );
    } else {
      dispatch(
        postAllRelevantAsync({ page, search, toast, navigate, dispatch })
      );
    }
  }, [dispatch, navigate, auth, search]);

  useEffect(() => {
    dispatch(postShowAsync({ slug, toast }));
  }, [slug, dispatch]);

  useEffect(() => {
    if (post) {
      var key = '';
      post?.belongsToTag?.map((tag) => {
        return (key += tag.name + ',');
      });
      setSearch(key);
      setShareUrl(filepath + '/post/' + post?.slug);
      setShareTitle(post?.title);
      setShareTag(key);
    } else {
      navigate(url.home(), { replace: true });
    }
  }, [post, navigate]);

  const nextPage = () => {
    var page = pageCount + 1;
    if (auth && auth?.id) {
      const authid = auth?.id;
      dispatch(
        postAllRelevantAsync({
          authid,
          page,
          search,
          toast,
          navigate,
          dispatch,
        })
      );
    } else {
      dispatch(
        postAllRelevantAsync({ page, search, toast, navigate, dispatch })
      );
    }
    dispatch(setPage(page));
  };

  const redirectToGoogleMaps = (lat, long) => {
    // Redirect to the Google Maps URL
    const googleMapsUrl = `https://www.google.com/maps/dir//${lat},${long}`;
    // Open the URL in a new tab
    window.open(googleMapsUrl, '_blank');
  };

  const commentSubmit = async (e) => {
    e.preventDefault();
    if (auth && auth.id && post && post.id) {
      var userId = auth.id;
      var postId = post.id;
      var postslug = slug;
      const commentData = new FormData();
      commentData.append('comment', comment);
      commentData.append('postId', postId);
      dispatch(
        commentCreateAsync({
          userId,
          postslug,
          postId,
          commentData,
          dispatch,
          navigate,
          toast,
        })
      );
    } else {
      toast.error('Please login first.');
      navigate(url.login());
    }
  };

  useEffect(() => {
    if (post && post.id) {
      var page = 1;
      var postId = post.id;
      dispatch(setPageComment(page));
      dispatch(commentAllAsync({ postId, page, toast, navigate, dispatch }));
    }
  }, [dispatch, navigate, post]);

  const nextPageComment = () => {
    if (post && post.id) {
      var page = pageCountComment + 1;
      var postId = post.id;
      dispatch(commentAllAsync({ postId, page, toast, navigate, dispatch }));
      dispatch(setPageComment(page));
    }
  };

  const commentEditSubmit = async (e, commentId) => {
    e.preventDefault();
    setVisibleEditFormId(null);
    if (auth && auth.id && post && post.id) {
      var userId = auth.id;
      var postId = post.id;
      var postslug = slug;
      const commentData = new FormData();
      commentData.append('postId', postId);
      commentData.append('comment', editComment);
      dispatch(
        commentUpdateAsync({
          userId,
          commentId,
          postId,
          postslug,
          commentData,
          dispatch,
          navigate,
          toast,
        })
      );
    } else {
      toast.error('Please login first.');
      navigate(url.login());
    }
  };

  const commentDeleteSubmit = async (e, commentId) => {
    e.preventDefault();
    setVisibleEditFormId(null);
    if (auth && auth.id && post && post.id) {
      var userId = auth.id;
      var postId = post.id;
      var postslug = slug;
      dispatch(
        commentDeleteAsync({
          userId,
          commentId,
          postId,
          postslug,
          dispatch,
          navigate,
          toast,
        })
      );
    } else {
      toast.error('Please login first.');
      navigate(url.login());
    }
  };

  const commentEditFormOpen = async (e, commentId) => {
    e.preventDefault();
    if (visibleEditFormId) {
      setVisibleEditFormId(null);
    } else {
      setVisibleEditFormId(commentId);
    }
  };

  const likeSubmit = (e) => {
    e.preventDefault();
    if (auth && auth.id && post && post.id) {
      var userId = auth.id;
      var postId = post.id;
      const likeData = new FormData();
      likeData.append('userId', userId);
      likeData.append('postId', postId);
      dispatch(
        likeCreateAsync({
          slug,
          likeData,
          dispatch,
          navigate,
          toast,
        })
      );
    } else {
      toast.error('Please login first.');
      navigate(url.login());
    }
  };

  useEffect(() => {
    if (post && post.id) {
      var postId = post.id;
      const viewData = new FormData();
      viewData.append('postId', postId);
      dispatch(
        viewCreateAsync({
          viewData,
          dispatch,
          navigate,
          toast,
        })
      );
    }
  }, [dispatch, navigate, post, slug]);

  useEffect(() => {
    if (post && post.id) {
      var userId = null;
      if (auth && auth.id) {
        userId = auth.id;
      }
      var postId = post.id;
      const likeData = new FormData();
      likeData.append('userId', userId);
      likeData.append('postId', postId);
      dispatch(
        likeCountAsync({
          likeData,
          dispatch,
          navigate,
          toast,
        })
      );
    }
  }, [post, auth, dispatch, navigate, slug]);

  return (
    <>
      <section className="watch-video">
        <div className="video-container">
          {/* <div className="video">
            <video
              src="/assets/images/vid-1.mp4"
              controls
              poster="/assets/images/post-1-1.png"
              id="video"
            ></video>
            {post?.file ? (
              <img
                src={apiFilepath + '/uploads/post/' + post?.file}
                alt="post"
                onError={(e) => {
                  e.currentTarget.src =
                    filepath + '/assets/static/no-image.jpg';
                }}
              />
            ) : (
              <img
                src={filepath + '/assets/static/no-image.jpg'}
                alt="post"
                onError={(e) => {
                  e.currentTarget.src =
                    filepath + '/assets/static/no-image.jpg';
                }}
              />
            )}
          </div> */}
          <h3 className="title">{post?.title}</h3>
          <div className="info border-none">
            <p className="date">
              <i className="fas fa-calendar"></i>
              <span>
                {post?.utcTime
                  ? moment(post?.utcTime).format('MMMM Do YYYY, h:mm:ss a')
                  : null}
              </span>
            </p>
          </div>
          <div className="info">
            <p className="date">
              <i className="fas fa-folder"></i>
              <span>
                {post?.belongsToTag &&
                  post?.belongsToTag.map((tag) => {
                    return (
                      <small className="tag" key={tag.id}>
                        {tag.name} &nbsp;
                      </small>
                    );
                  })}
              </span>
            </p>
          </div>
          <div className="video">
            {/* <video
              src="/assets/images/vid-1.mp4"
              controls
              poster="/assets/images/post-1-1.png"
              id="video"
            ></video> */}
            {post?.file ? (
              <img
                src={apiFilepath + '/uploads/post/' + post?.file}
                alt="post"
                onError={(e) => {
                  e.currentTarget.src =
                    filepath + '/assets/static/no-image.jpg';
                }}
              />
            ) : (
              <img
                src={filepath + '/assets/static/no-image.jpg'}
                alt="post"
                onError={(e) => {
                  e.currentTarget.src =
                    filepath + '/assets/static/no-image.jpg';
                }}
              />
            )}
          </div>
          <div className="tutor">
            <img
              src={
                post?.belongsToUser?.avatar
                  ? apiFilepath +
                    '/uploads/avatar/' +
                    post?.belongsToUser?.avatar
                  : filepath + '/assets/images/company-logo-1658436134701.png'
              }
              alt="avatar"
              loading="lazy"
              onError={(e) => {
                e.currentTarget.src = filepath + '/assets/images/user.png';
              }}
            />
            <div>
              <h3>
                {post?.belongsToUser?.name ? post?.belongsToUser?.name : 'User'}
              </h3>
              <span>
                {post?.belongsToUser?.username
                  ? post?.belongsToUser?.username
                  : 'Username'}
              </span>
            </div>
          </div>
          <div className="tutor">
            <div className="info border-none">
              <h3>
                <i className="fas fa-map-marker-alt"></i> ({post?.latitude},{' '}
                {post?.longitude}) {post?.address}
              </h3>
              <span
                onClick={() =>
                  redirectToGoogleMaps(post?.latitude, post?.longitude)
                }
              >
                <i className="fas fa-location-arrow"></i> Open Google Map
                {post?.distance ? ` : ${post?.distance} km  ` : ''}
                {post?.direction}
              </span>
            </div>
          </div>
          <div className="info">
            <b>Share :</b>
            <p className="date">
              <FacebookShareButton url={shareUrl} hashtag={shareTag}>
                <i className="fab fa-facebook-square"></i>
              </FacebookShareButton>
            </p>
            <p className="date">
              <TwitterShareButton url={shareUrl} title={shareTitle}>
                <i className="fab fa-twitter-square"></i>
              </TwitterShareButton>
            </p>
            <p className="date">
              <WhatsappShareButton
                url={shareUrl}
                title={shareTitle}
                separator={shareTag}
              >
                <i className="fab fa-whatsapp-square"></i>
              </WhatsappShareButton>
            </p>
            <p className="date">
              <LinkedinShareButton
                url={shareUrl}
                title={shareTitle}
                summary={shareTag}
                source={shareUrl}
              >
                <i className="fab fa-linkedin"></i>
              </LinkedinShareButton>
            </p>
          </div>
          <div className="flex">
            {liked === true ? (
              <button onClick={likeSubmit}>
                <i
                  className="fa fa-heart"
                  aria-hidden="true"
                  style={{ color: 'blue' }}
                ></i>
                <span>{likeCount} Likes</span>
              </button>
            ) : (
              <button onClick={likeSubmit}>
                <i className="far fa-heart"></i>
                <span>{likeCount} Likes</span>
              </button>
            )}
            <button>
              <i className="far fa-eye"></i>
              <span>{viewCount} Views</span>
            </button>
          </div>
          <p
            className="description"
            style={{ padding: '1rem' }}
            dangerouslySetInnerHTML={{
              __html: post && post?.description ? post?.description : '',
            }}
          ></p>
        </div>
      </section>
      {postRelevantLists && postRelevantLists.length > 1 && (
        <section className="courses">
          <h1 className="heading">Relevant Posts</h1>
          <div
            id="scrollablePost"
            style={{
              height: '50vh',
              overflow: 'auto',
              display: 'flex',
            }}
          >
            <InfiniteScroll
              dataLength={postRelevantLists.length}
              next={nextPage}
              hasMore={hasMore}
              loader={
                <div style={{ width: '100%' }} className="btn">
                  Loading...
                </div>
              }
              endMessage={
                <div style={{ width: '100%' }} className="btn">
                  No more posts
                </div>
              }
              scrollThreshold={0.9} // Optional threshold to trigger next load
              scrollableTarget="scrollablePost"
            >
              <div className="box-container">
                {postRelevantLists
                  .filter((postRelevant) => postRelevant.id !== post.id)
                  .map((postRelevant, i) => (
                    <div className="box" key={i}>
                      <div className="tutor">
                        <img
                          src={
                            postRelevant?.belongsToUser?.avatar
                              ? apiFilepath +
                                '/uploads/avatar/' +
                                postRelevant?.belongsToUser?.avatar
                              : filepath +
                                '/assets/images/company-logo-1658436134701.png'
                          }
                          alt="avatar"
                          onError={(e) => {
                            e.currentTarget.src =
                              filepath + '/assets/images/user.png';
                          }}
                        />
                        <div className="info">
                          <h3>
                            {postRelevant?.belongsToUser?.name
                              ? postRelevant?.belongsToUser?.name
                              : 'User'}
                          </h3>
                          <span>
                            {postRelevant?.utcTime
                              ? moment(postRelevant?.utcTime).format(
                                  'MMMM Do YYYY, h:mm:ss a'
                                )
                              : null}
                          </span>
                        </div>
                      </div>
                      <Link
                        to={`/post/${postRelevant?.slug}`}
                        key={postRelevant?.id ? postRelevant?.id : i}
                      >
                        <div className="thumb">
                          {postRelevant?.file ? (
                            <img
                              src={
                                apiFilepath +
                                '/uploads/post/' +
                                postRelevant?.file
                              }
                              alt="post"
                              onError={(e) => {
                                e.currentTarget.src =
                                  filepath + '/assets/static/no-image.jpg';
                              }}
                            />
                          ) : (
                            <img
                              src={filepath + '/assets/static/no-image.jpg'}
                              alt="post"
                              onError={(e) => {
                                e.currentTarget.src =
                                  filepath + '/assets/static/no-image.jpg';
                              }}
                            />
                          )}
                          <span>
                            <i className="fas fa-eye"></i>
                          </span>
                        </div>
                      </Link>
                      <div className="tutor">
                        <div className="info">
                          <h3>
                            <i className="fas fa-map-marker-alt"></i> (
                            {postRelevant?.latitude}, {postRelevant?.longitude}){' '}
                            {postRelevant?.address}
                          </h3>
                          <span
                            onClick={() =>
                              redirectToGoogleMaps(
                                postRelevant?.latitude,
                                postRelevant?.longitude
                              )
                            }
                          >
                            <i className="fas fa-location-arrow"></i> Open
                            Google Map
                            {postRelevant?.distance
                              ? ` : ${postRelevant?.distance} km  `
                              : ''}
                            {postRelevant?.direction}
                          </span>
                        </div>
                      </div>
                      <h3 className="title">
                        {postRelevant?.title ? postRelevant?.title : null}
                      </h3>
                    </div>
                  ))}
              </div>
            </InfiniteScroll>
          </div>
        </section>
      )}
      <section className="comments">
        <h1 className="heading">comments</h1>

        <div className="add-comment">
          <h3>add comment</h3>
          <textarea
            name="comment_box"
            placeholder="enter your comment"
            required
            maxLength="1000"
            cols="30"
            rows="10"
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
          <input
            type="submit"
            value="add comment"
            className="inline-btn"
            name="add_comment"
            onClick={commentSubmit}
          />
        </div>

        <h1 className="heading">user comments</h1>
        <div className="box-container">
          <InfiniteScroll
            dataLength={commentLists.length}
            next={nextPageComment}
            hasMore={hasMoreComment}
            loader={
              <div style={{ width: '100%' }} className="btn">
                Loading...
              </div>
            }
            endMessage={
              <div style={{ width: '100%' }} className="btn">
                No more comments
              </div>
            }
            scrollThreshold={0.9} // Optional threshold to trigger next load
          >
            {commentLists &&
              commentLists.length > 0 &&
              commentLists.map((comment, i) => (
                <div className="box" style={{ margin: '1rem' }} key={i}>
                  <div className="user">
                    <img
                      src={
                        comment?.belongsToUser?.avatar
                          ? apiFilepath +
                            '/uploads/avatar/' +
                            comment?.belongsToUser?.avatar
                          : filepath +
                            '/assets/images/company-logo-1658436134701.png'
                      }
                      alt="avatar"
                      onError={(e) => {
                        e.currentTarget.src =
                          filepath + '/assets/images/user.png';
                      }}
                    />
                    <div>
                      <h3>
                        {comment?.belongsToUser?.name
                          ? comment?.belongsToUser?.name
                          : 'User'}
                      </h3>
                      <span>
                        {comment?.publishTime
                          ? moment(comment?.publishTime).format(
                              'MMMM Do YYYY, h:mm:ss a'
                            )
                          : null}
                      </span>
                    </div>
                  </div>
                  <div className="comment-box">{comment?.comment}</div>
                  {comment?.userId &&
                    auth?.id &&
                    comment.userId === auth.id && (
                      <>
                        {comment.id === visibleEditFormId && (
                          <div className="add-comment">
                            <h3>edit comment</h3>
                            <textarea
                              name="comment_box"
                              placeholder="enter your comment"
                              required
                              maxLength="1000"
                              cols="30"
                              rows="10"
                              onChange={(e) => setEditComment(e.target.value)}
                            ></textarea>
                            <input
                              type="submit"
                              value="edit comment"
                              className="inline-btn"
                              name="edit_comment"
                              onClick={(e) => commentEditSubmit(e, comment.id)}
                            />
                          </div>
                        )}

                        <div className="flex-btn">
                          <input
                            type="submit"
                            value="edit comment"
                            name="edit_comment"
                            className="inline-option-btn"
                            onClick={(e) => commentEditFormOpen(e, comment.id)}
                          />
                          <input
                            type="submit"
                            value="delete comment"
                            name="delete_comment"
                            className="inline-delete-btn"
                            onClick={(e) => commentDeleteSubmit(e, comment.id)}
                          />
                        </div>
                      </>
                    )}
                </div>
              ))}
          </InfiniteScroll>
        </div>
      </section>
    </>
  );
};

export default PostView;
