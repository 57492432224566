import React, { useEffect, useState } from 'react';
import { ProfileCourseAsync } from '../redux/slices/CourseSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { apiFilepath,filepath } from '../helpers/urlConfig';
import * as url from '../helpers/url';

const MyProfileCourse = () => {
  const dispatch = useDispatch();

  const { auth } = useSelector((state) => state.authReducer);
  const [id] = useState(auth?.id);
  const { profilecourses } = useSelector((state) => state.courseReducer);
  useEffect(() => {
    dispatch(ProfileCourseAsync({ id, toast }));
  }, [dispatch, id]);

  return (
    <>
      <section className="playlist-details">
        <h1 className="heading">My All courses</h1>

        <div className="row">
          <div className="column">
            <form action="" method="post" className="save-playlist">
              <button type="submit">
                <i className="far fa-bookmark"></i> <span>save playlist</span>
              </button>
            </form>

            <div className="thumb">
              <img src={filepath +'/assets/images/thumb-1.png'} alt="" />
              <span>10 videos</span>
            </div>
          </div>
          <div className="column">
            <div className="tutor">
              <img src={filepath +'/assets/images/pic-2.jpg'} alt="" />
              <div>
                <h3>john deo</h3>
                <span>21-10-2022</span>
              </div>
            </div>

            <div className="details">
              <h3>complete HTML tutorial</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum
                minus reiciendis, error sunt veritatis exercitationem deserunt
                velit doloribus itaque voluptate.
              </p>
              <Link to={url.courseCreate()} className="inline-btn">
                Create a Course
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="playlist-videos">
        <h1 className="heading">Courses List</h1>

        <div className="box-container">
          {profilecourses &&
            profilecourses.map((course, i) => (
              <div className="box" key={course?.id ? course?.id : i}>
                <Link to={url.myProfileCourseView(course?.slug)}>
                  <i className="fas fa-eye"></i>
                  {course?.file ? (
                    <img
                      src={apiFilepath + '/uploads/course/' + course?.file}
                      alt="course"
                      onError={(e) => {
                        e.currentTarget.src = filepath +'/assets/static/no-image.jpg';
                      }}
                    />
                  ) : (
                    <img
                      src={filepath +'/assets/static/no-image.jpg'}
                      alt="course"
                      onError={(e) => {
                        e.currentTarget.src = filepath +'/assets/static/no-image.jpg';
                      }}
                    />
                  )}
                  <h3>{course?.title ? course?.title : null}</h3>
                </Link>
                {parseInt(course?.belongsToUser?.id) === parseInt(id) && (
                  <Link
                    to={url.courseEdit(course?.userId, course?.slug)}
                    className="btn"
                  >
                    Edit
                  </Link>
                )}
                {parseInt(course?.belongsToUser?.id) === parseInt(id) && (
                  <Link
                    to={url.postAddtoCourse(course?.userId, course?.slug)}
                    className="btn"
                  >
                    Add Post
                  </Link>
                )}
                {parseInt(course?.belongsToUser?.id) === parseInt(id) && (
                  <Link
                    to={url.courseDelete(course?.userId, course?.slug)}
                    className="delete-btn"
                  >
                    Delete
                  </Link>
                )}
              </div>
            ))}
        </div>
      </section>
    </>
  );
};

export default MyProfileCourse;
