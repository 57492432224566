import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { tagAllAsync } from '../redux/slices/TagSlice';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as url from '../helpers/url';
import { postShowAsync, postUpdateAsync } from '../redux/slices/PostSlice';
import { apiFilepath, filepath } from '../helpers/urlConfig';
import Webcam from 'react-webcam';
import CreatableSelect from 'react-select/creatable';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

const PostEdit = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { auth } = useSelector((state) => state.authReducer);
  const { tags } = useSelector((state) => state.tagReducer);
  const [id] = useState(auth?.id);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState();
  const [fileThumb, setFileThumb] = useState();
  const [defaultTag, setDefaultTag] = useState([]);
  const { userId, slug } = useParams();
  const { post } = useSelector((state) => state.postReducer);
  const [suggestions, setSuggestions] = useState();
  const [publishDate, setPublishDate] = useState(new Date());

  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [capturing, setCapturing] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [utcTime, setUtcTime] = useState(null);
  const [facingMode, setFacingMode] = useState('environment');
  const [flashlightOn, setFlashlightOn] = useState(false);
  const [flashlightSupported, setFlashlightSupported] = useState(true); // Assume flashlight support by default
  const [captureTimer, setCaptureTimer] = useState(null); // State for capture timer
  const [remainingTime, setRemainingTime] = useState(5); // State for remaining time
  const [streamStarted, setStreamStarted] = useState(false);

  const toggleFacingMode = () => {
    setFacingMode(facingMode === 'user' ? 'environment' : 'user');
  };

  const startStream = () => {
    setStreamStarted(true);
  };

  const capture = () => {
    if (webcamRef && webcamRef.current) {
      if (flashlightOn) {
        toggleFlashlight();
      }
      const imageSrc = webcamRef.current.getScreenshot({
        width: 1080,
        height: 1080,
      });
      const imageSrcThumb = webcamRef.current.getScreenshot({
        width: 600,
        height: 600,
      });
      setImageSrc(imageSrc);
      const imgsrc = dataURLtoFile(imageSrc, 'original');
      const imgsrcthumb = dataURLtoFile(imageSrcThumb, 'thumb');
      setFile(imgsrc);
      setFileThumb(imgsrcthumb);
      clearInterval(captureTimer); // Clear the capture timer
      setRemainingTime(5); // Reset remaining time
    } else {
      stopCapture();
    }
  };

  const recapture = () => {
    setImageSrc(null);
    clearInterval(captureTimer); // Clear the capture timer
    setRemainingTime(5); // Reset remaining time
  };

  const startCapture = () => {
    setCapturing(true);
  };

  const stopCapture = () => {
    setCapturing(false);
    clearInterval(captureTimer); // Clear the capture timer
    setRemainingTime(5); // Reset remaining time
  };

  const delayedCapture = () => {
    setRemainingTime(5); // Reset the remaining time
    setCaptureTimer(
      setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime === 1) {
            clearInterval(captureTimer);
            capture();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000)
    );
  };

  const toggleFlashlight = () => {
    if (webcamRef.current && 'getVideoTracks' in webcamRef.current.stream) {
      const track = webcamRef.current.stream.getVideoTracks()[0];
      if (track && 'applyConstraints' in track) {
        if ('torch' in track.getCapabilities()) {
          track.applyConstraints({ advanced: [{ torch: !flashlightOn }] }); // Toggle flashlight state
          setFlashlightOn((prevState) => !prevState); // Update flashlight state
        } else {
          setFlashlightSupported(false);
          setFlashlightOn(false);
        }
      }
    }
  };

  const toggleFlashlightFun = () => {
    if (webcamRef.current && 'getVideoTracks' in webcamRef.current.stream) {
      const track = webcamRef.current.stream.getVideoTracks()[0];
      if (track && 'applyConstraints' in track) {
        setFlashlightSupported(true);
        setFlashlightOn(!flashlightOn);
      }
    }
  };

  // Function to convert data URL to a file
  const dataURLtoFile = (dataURL, type) => {
    var filename = `neartome-post-${Date.now()}.png`;
    if (type === 'thumb') {
      filename = `thumb-neartome-post-${Date.now()}.png`;
    }
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  // Function to get geolocation
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          const timestamp = position.timestamp; // UTC timestamp from geolocation API
          const utcTimeString = new Date(timestamp).toISOString();
          const utcDate = new Date(utcTimeString);
          setUtcTime(utcDate);
        },
        (error) => {
          console.error('Error getting geolocation:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    if (post && post?.title) {
      setTitle(post?.title);
      setDescription(post?.description);
      var defcatData = post?.belongsToTag.map((opt) => ({
        value: opt.id,
        label: opt.name,
      }));
      setDefaultTag(defcatData);
      if (post.publishDate) {
        setPublishDate(new Date(post.publishDate));
      }
    }
    getLocation();
  }, [post]);

  useEffect(() => {
    if (auth && userId && parseInt(auth.id) === parseInt(userId)) {
      dispatch(postShowAsync({ slug, toast }));
    } else {
      toast.error('You are not authorized to edit this post');
      navigate(url.myProfilePost());
    }
  }, [auth, userId, slug, dispatch, navigate]);

  useEffect(() => {
    dispatch(tagAllAsync({ toast }));
  }, [dispatch]);

  // const fileUpload = (e) => {
  //   setFile(e.target.files[0]);
  // };

  const postUpdate = async (e) => {
    e.preventDefault();
    const publishDateUtc = moment.utc(publishDate).toISOString();
    const postData = new FormData();
    postData.append('title', title);
    postData.append('description', description);
    postData.append('file', file);
    postData.append('fileThumb', fileThumb);
    postData.append('tagIds', JSON.stringify(defaultTag));
    postData.append('latitude', latitude);
    postData.append('longitude', longitude);
    postData.append('utcTime', utcTime);
    postData.append('publishDate', publishDateUtc);
    dispatch(
      postUpdateAsync({ id, slug, postData, dispatch, navigate, toast })
    );
  };

  useEffect(() => {
    const suggestions = tags.map((tag) => ({
      value: tag.id.toString(),
      label: tag.name,
    }));
    setSuggestions(suggestions);
  }, [tags]);

  const selectTag = (e) =>
    setDefaultTag(
      e.map((option) => ({ value: option.value, label: option.label }))
    );

  const setPublishDateFun = (date) => {
    setPublishDate(date);
  };

  // Define your Quill modules
  const Quillmodules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['link', 'image', 'video'],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
    ],
  };

  return (
    <section className="form-container">
      <form action="" method="post" encType="multipart/form-data">
        <h3>Post Edit</h3>
        <p>Image</p>
        {/* <input
          type="file"
          accept="image/*"
          className="box"
          onChange={fileUpload}
        /> */}
        {post && post.file && (
          <img
            src={apiFilepath + '/uploads/post/' + post.file}
            alt="Capture"
            style={{ width: '100%', height: 'auto', padding: '1rem' }}
            onError={(e) => {
              e.currentTarget.src = filepath + '/assets/images/user.png';
            }}
          />
        )}
        <div>
          {imageSrc ? (
            <>
              <img
                src={imageSrc}
                alt="Captured"
                style={{ width: '100%', height: 'auto', padding: '1rem' }}
              />
              <span className="btn" onClick={recapture}>
                <i className="fa fa-redo"></i>
              </span>
            </>
          ) : capturing ? (
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: 'auto',
                zIndex: 1,
              }}
            >
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
                videoConstraints={{
                  facingMode: facingMode,
                  width: 1080,
                  height: 1080,
                }}
                onUserMedia={() => startStream()}
              />

              {streamStarted && (
                <>
                  <div
                    className=""
                    style={{
                      position: 'absolute',
                      top: '5%',
                      right: '5%',
                      width: '90%',
                      left: '10px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      zIndex: 2,
                    }}
                  >
                    <span
                      className="btn"
                      style={{
                        padding: '5px 10px',
                        marginRight: '10px',
                        border: '1px solid #ccc',
                        cursor: 'pointer',
                        opacity: '0.5',
                        // backgroundColor: 'transparent',
                      }}
                      onClick={capture}
                    >
                      <i className="fas fa-camera"></i>
                    </span>
                    <span
                      className="btn"
                      style={{
                        padding: '5px 10px',
                        marginRight: '10px',
                        border: '1px solid #ccc',
                        cursor: 'pointer',
                        opacity: '0.5',
                        // backgroundColor: 'transparent',
                      }}
                      onClick={toggleFacingMode}
                      title="Camera Mode"
                    >
                      <i className="fas fa-camera-rotate"></i>
                    </span>
                  </div>
                  <div
                    className=""
                    style={{
                      position: 'absolute',
                      bottom: '5%',
                      left: '5%',
                      right: '5%',
                      width: '90%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      zIndex: 2,
                    }}
                  >
                    <span
                      className="btn"
                      style={{
                        padding: '5px 10px',
                        marginRight: '10px',
                        border: '1px solid #ccc',
                        cursor: 'pointer',
                        opacity: '0.5',
                        // backgroundColor: 'transparent',
                      }}
                      onClick={delayedCapture}
                      title="Camera Timer"
                    >
                      <i className="fas fa-clock"></i> {remainingTime} Sec
                    </span>
                    <span
                      className="btn"
                      style={{
                        padding: '5px 10px',
                        marginRight: '10px',
                        border: '1px solid #ccc',
                        cursor: 'pointer',
                        opacity: '0.5',
                        // backgroundColor: 'transparent',
                      }}
                      onClick={toggleFlashlightFun}
                      title="flashlight"
                    >
                      {flashlightSupported ? (
                        <>
                          {flashlightOn ? (
                            <i className="fas fa-lightbulb"></i>
                          ) : (
                            <i className="far fa-lightbulb"></i>
                          )}
                        </>
                      ) : (
                        <>
                          <i className="far fa-lightbulb"></i>
                        </>
                      )}
                    </span>
                    <span
                      className="btn"
                      style={{
                        padding: '5px 10px',
                        marginRight: '10px',
                        border: '1px solid #ccc',
                        cursor: 'pointer',
                        opacity: '0.5',
                        // backgroundColor: 'transparent',
                      }}
                      onClick={stopCapture}
                      title="Camera Stop"
                    >
                      <i className="fa fa-stop"></i>
                    </span>
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              <span className="btn" onClick={startCapture}>
                <i className="fas fa-camera"></i>
              </span>
            </>
          )}
        </div>
        {imageSrc && (
          <>
            <p>
              Tag <span>*</span>
            </p>
            <div className="box react-tag">
              <CreatableSelect
                isMulti
                options={suggestions}
                onChange={selectTag}
                value={defaultTag}
              />
            </div>
            <p>
              Title <span>*</span>
            </p>
            <input
              type="text"
              name="title"
              placeholder="Title"
              required
              maxLength="200"
              className="box"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <p>
              Description <span>*</span>
            </p>
            <div className="box">
              <ReactQuill
                value={description}
                onChange={(value) => {
                  setDescription(value);
                }}
                modules={Quillmodules}
                className="react-quill"
                theme="snow"
              />
            </div>
            <p>Publish Date</p>
            <Datetime
              className="box"
              value={publishDate}
              onChange={setPublishDateFun}
              inputProps={{ placeholder: 'Select Date and Time' }}
            />
            <input
              type="submit"
              value="Update"
              name="submit"
              className="btn"
              onClick={postUpdate}
            />
          </>
        )}
      </form>
    </section>
  );
};

export default PostEdit;
