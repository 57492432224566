import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import * as url from '../helpers/url';

const Error = () => {
  let navigate = useNavigate();

  useEffect(() => {
    navigate(url.home(), { replace: true });
  }, [navigate]);

  return (
    <section className="home-grid">
      <div className="box-container">
        <div className="box">
          <h3 className="title">Error : 404</h3>
          <p className="tutor">Page not found !</p>
          <Link to={`/`} className="inline-btn">
            Home
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Error;
