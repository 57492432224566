// export const apiBase = "http://localhost:8000/api";
// export const host = "http://localhost:8000";
// export const apiFilepath = "http://localhost:8000";
// export const filepath = "http://localhost:3000";


export const apiBase = "https://adminneartome.digitolearn.com/api";
export const host = "https://adminneartome.digitolearn.com";
export const apiFilepath = "https://adminneartome.digitolearn.com";
export const filepath = "https://neartome.digitolearn.com";


export const googleClientId = "73958955983-17cldh5cql293d2jjuij3023jc1cb9vn.apps.googleusercontent.com";