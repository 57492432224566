import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { registerAsync } from '../redux/slices/AuthSlice';
import { locationCreateAsync } from '../redux/slices/LocationSlice';
import { Link } from 'react-router-dom';
import * as url from '../helpers/url';

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locationcreate } = useSelector((state) => state.locationReducer);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCpassword] = useState('');

  const registerSubmit = (e) => {
    e.preventDefault();
    if(locationcreate){
      const userData = {
        name: name,
        email: email,
        password: password,
        cpassword: cpassword,
        locationcreate: locationcreate,
      };
      dispatch(registerAsync({ userData, navigate, toast }));
    }else{
      toast.error('Click here to give permisssion to use geo Location');
    }
  };

  const handleLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          const utcOffset = new Date().getTimezoneOffset() / -60;
          const locationData = {
            latitude: latitude.toString(),
            longitude: longitude.toString(),
            status: 0,
            utcOffset: utcOffset,
          };
          dispatch(locationCreateAsync({ locationData, navigate, toast }));        
      }, (error) => {
        console.error('Error getting geolocation:', error);
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  return (
    <section className="form-container">
      <form action="" method="post" encType="multipart/form-data">
        <h3><i class="info-icon fas fa-info-circle" title="Give permisssion to use geo Location before registration."></i> register now</h3>
        <p>
        <span className="btn" type="button" onClick={handleLocation}><i className='fas fa-map-marker-alt'></i> Click here to give permisssion to use geo Location</span>
        </p>
        <p>
          your name <span>*</span>
        </p>
        <input
          type="text"
          name="name"
          placeholder="enter your name"
          required
          maxLength="50"
          className="box"
          onChange={(e) => setName(e.target.value)}
        />
        <p>
          your email <span>*</span>
        </p>
        <input
          type="email"
          name="email"
          placeholder="enter your email"
          required
          maxLength="50"
          className="box"
          onChange={(e) => setEmail(e.target.value)}
        />
        <p>
          your password <span><i class="info-icon fas fa-info-circle" title="Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character."></i></span>
        </p>
        <input
          type="password"
          name="pass"
          placeholder="enter your password"
          required
          maxLength="20"
          className="box"
          onChange={(e) => setPassword(e.target.value)}
        />
        <p>
          confirm password <span>*</span>
        </p>
        <input
          type="password"
          name="c_pass"
          placeholder="confirm your password"
          required
          maxLength="20"
          className="box"
          onChange={(e) => setCpassword(e.target.value)}
        />
        <p>
          By clicking "Register New" you agree to our
          <Link to={url.termsAndCondition()}>
            <span> Terms of Service </span>
          </Link>
          &
          <Link to={url.privacyPolicy()}>
            <span> Privacy Policy </span>
          </Link>
        </p>
        <input
          type="submit"
          value="register new"
          name="submit"
          className="btn"
          onClick={registerSubmit}
        />
      </form>
    </section>
  );
};

export default Register;
