import React from 'react';
// import { Link } from 'react-router-dom';
import * as url from '../helpers/url';
import { filepath } from '../helpers/urlConfig';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
  let navigate = useNavigate();
  let body = document.body;

  const closeBtnClickFun = () => {
    let sideBar = document.querySelector('.side-bar');
    sideBar.classList.remove('active');
    body.classList.remove('active');
  };

  const openHomeFun = () => {
    closeBtnClickFun();
    navigate(url.home());
  };

  const openAboutFun = () => {
    closeBtnClickFun();
    navigate(url.about());
  };

  const openContactFun = () => {
    closeBtnClickFun();
    navigate(url.contact());
  };

  const openPostCreateFun = () => {
    closeBtnClickFun();
    navigate(url.postCreate());
  };

  const openDigitolearnFun = () => {
    closeBtnClickFun();
    window.location.href = url.digitolearn();
  };

  return (
    <div className="side-bar">
      <div id="close-btn" onClick={() => closeBtnClickFun()}>
        <i className="fas fa-times"></i>
      </div>

      <div className="profile">
        <img
          src={filepath + '/assets/static/neartome.PNG'}
          className="image"
          alt=""
        />
        <h3 className="name">NearToMe</h3>
        <p className="role">
          Discover, Share, and Experience Real-Time, Location-Based Images
          Without AI or Photoshop Alterations.
        </p>
        <div onClick={openPostCreateFun} className="btn">
          Create a Post
        </div>
        {/* <Link to={url.courseCreate()} className="btn">
          Create a Course
        </Link> */}
      </div>

      <nav className="navbar">
        {/* <Link className="link" to={url.home()}>
          <i className="fas fa-home"></i>
          <span>Home</span>
        </Link> */}
        <div className="link" onClick={openHomeFun}>
          <i className="fas fa-sticky-note"></i>
          <span>Home</span>
        </div>
        <div className="link" onClick={openAboutFun}>
          <i className="fas fa-question"></i>
          <span>About Us</span>
        </div>
        {/* <Link className="link" to={url.courses()}>
          <i className="fas fa-graduation-cap"></i>
          <span>Courses</span>
        </Link> */}
        {/* <Link className="link" to={url.teachers()}>
          <i className="fas fa-chalkboard-user"></i>
          <span>Teachers</span>
        </Link> */}
        <div className="link" onClick={openDigitolearnFun}>
          <i className="fas fa-sticky-note"></i>
          <span>Digitolearn</span>
        </div>
        <div className="link" onClick={openContactFun}>
          <i className="fas fa-headset"></i>
          <span>Contact Us</span>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
