import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as url from '../helpers/url';
import {
  postInCourseShowAsync,
  postEdittoCourseAsync,
} from '../redux/slices/CourseSlice';
import Select from 'react-select';
import { ProfilePostAsync } from '../redux/slices/PostSlice';

const PostEdittoCourse = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { auth } = useSelector((state) => state.authReducer);
  const [id] = useState(auth?.id);
  const [courseTitle, setCourseTitle] = useState('');
  const [postno, setPostno] = useState('');
  const [post, setPost] = useState('');
  const [postId, setPostId] = useState('');
  const [courseSlug, setCourseSlug] = useState('');
  const { userId, postInCourseId } = useParams();
  const { postInCourse } = useSelector((state) => state.courseReducer);
  const { profileposts } = useSelector((state) => state.postReducer);

  useEffect(() => {
    if (postInCourse && postInCourse?.belongsToCourse) {
      setCourseTitle(postInCourse?.belongsToCourse?.title);
      setCourseSlug(postInCourse?.belongsToCourse?.slug);
      setPostno(postInCourse?.postno);
      setPost({
        label: postInCourse?.belongsToPost?.title,
        value: postInCourse?.belongsToPost?.id,
      });
    }
  }, [postInCourse]);

  useEffect(() => {
    if (auth && userId && parseInt(auth.id) === parseInt(userId)) {
      dispatch(postInCourseShowAsync({ postInCourseId, toast }));
      dispatch(ProfilePostAsync({ id, toast }));
    } else {
      toast.error('You are not authorized to edit this course');
      navigate(url.myProfileCourse());
    }
  }, [id, auth, userId, postInCourseId, dispatch, navigate]);

  const postEditSubmit = async (e) => {
    e.preventDefault();
    const courseData = new FormData();
    courseData.append('postInCourseId', postInCourseId);
    courseData.append('postno', postno);
    courseData.append('postId', postId);
    dispatch(
      postEdittoCourseAsync({
        id,
        courseSlug,
        courseData,
        dispatch,
        navigate,
        toast,
      })
    );
  };

  const options = profileposts.map((post) => {
    return {
      label: post.title,
      value: post.id,
    };
  });

  const postChange = (selectedOption) => {
    setPost(selectedOption);
    setPostId(selectedOption.value);
  };

  return (
    <section className="form-container">
      <form action="" method="post" encType="multipart/form-data">
        <h3>Edit Post In Course</h3>
        <p>
          Course <span>*</span>
        </p>
        <input
          type="text"
          name="course"
          placeholder="Course"
          required
          readOnly
          maxLength="50"
          className="box"
          value={courseTitle}
        />
        <p>
          Post No. <span>*</span>
        </p>
        <input
          type="number"
          name="postno"
          placeholder="Post No"
          required
          maxLength="50"
          className="box"
          value={postno}
          onChange={(e) => setPostno(e.target.value)}
        />
        <p>
          Post <span>*</span>
        </p>
        <div className="box">
          <Select value={post} onChange={postChange} options={options} />
        </div>
        <input
          type="submit"
          value="Submit"
          name="submit"
          className="btn"
          onClick={postEditSubmit}
        />
      </form>
    </section>
  );
};

export default PostEdittoCourse;
