import React from 'react';

const TermsAndCondition = () => {
  return (
    <>
      <section className="about">
        <div className="row">
          {/* <div className="image">
            <img src={filepath + '/assets/images/about-img.svg'} alt="" />
          </div> */}

          <div className="content">
            <h3>Terms and Conditions</h3>
            <p>
              Please read these Terms and Conditions carefully before using our
              NearToMe. These Terms govern your
              access to and use of the Platform, including any content,
              features, and services provided by us. By accessing or using the
              Platform, you agree to be bound by these Terms. If you do not
              agree with any part of these Terms, you may not access or use the
              Platform.{' '}
            </p>
            <p>1. Use of the Platform </p>
            <p>
              a. Eligibility: You must be at least 13 years old to use the
              Platform. If you are under the age of 18, you represent that you
              have obtained parental or guardian consent to use the Platform.{' '}
            </p>
            <p>
              b. Account Creation: To access certain features of the Platform,
              you may be required to create an account. You agree to provide
              accurate and complete information during the registration process
              and to keep your account information updated. You are responsible
              for maintaining the confidentiality of your account credentials
              and for all activities that occur under your account.{' '}
            </p>
            <p>
              c. Prohibited Activities: You agree not to engage in any of the
              following activities while using the Platform:{' '}
            </p>
            <p>- Violating any applicable laws or regulations. </p>
            <p>
              - Infringing upon the intellectual property rights of others.{' '}
            </p>
            <p>
              - Uploading, transmitting, or distributing any harmful or
              malicious content, including viruses or malware.{' '}
            </p>
            <p>
              - Interfering with or disrupting the integrity or performance of
              the Platform.{' '}
            </p>
            <p>
              - Engaging in any unauthorized access or use of the Platform or
              its users' information.{' '}
            </p>
            <p>- Engaging in any fraudulent or deceptive activities. </p>
            <p>2. Content and Intellectual Property Rights </p>
            <p>
              a. Content Ownership: The Platform and all its content, including
              but not limited to text, graphics, images, videos, and software,
              are the property of the Platform's owners or its licensors and are
              protected by intellectual property laws.{' '}
            </p>
            <p>
              b. User-generated Content: By using the Platform, you may
              contribute user-generated content, such as comments, posts, or
              submissions. You retain ownership of your user-generated content,
              but you grant us a non-exclusive, royalty-free, worldwide license
              to use, reproduce, modify, adapt, publish, and display your
              content in connection with the operation and promotion of the
              Platform.{' '}
            </p>
            <p>
              c. Restrictions: You agree not to copy, modify, distribute, sell,
              lease, or exploit any content from the Platform without the
              express written permission of the content owners.{' '}
            </p>
            <p>3. Privacy </p>
            <p>
              We collect and process personal information as described in our
              Privacy Policy. By using the Platform, you consent to the
              collection and use of your personal information in accordance with
              our Privacy Policy.{' '}
            </p>
            <p>4. Disclaimer of Warranties </p>
            <p>
              a. The Platform is provided on an "as is" and "as available"
              basis. We make no warranties or representations about the
              accuracy, reliability, or completeness of any content or
              information provided through the Platform.{' '}
            </p>
            <p>
              b. We disclaim all warranties, whether express, implied, or
              statutory, including but not limited to warranties of
              merchantability, fitness for a particular purpose,
              non-infringement, and uninterrupted or error-free operation.{' '}
            </p>
            <p>5. Limitation of Liability </p>
            <p>
              To the extent permitted by applicable laws, we shall not be liable
              for any direct, indirect, incidental, special, consequential, or
              exemplary damages arising out of or in connection with your use of
              the Platform, even if we have been advised of the possibility of
              such damages.{' '}
            </p>
            <p>6. Indemnification </p>
            <p>
              You agree to indemnify, defend, and hold us harmless from any
              claims, damages, losses, liabilities, costs, or expenses
              (including reasonable attorneys' fees) arising out of or in
              connection with your use of the Platform or any violation of these
              Terms.{' '}
            </p>
            <p>7. Modification and Termination </p>
            <p>
              We reserve the right to modify, suspend, or terminate the Platform
              or any part thereof at any time without notice. We may also update
              or modify these
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndCondition;
