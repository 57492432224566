import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as url from '../helpers/url';
import {
  courseShowAsync,
  postAddtoCourseAsync,
} from '../redux/slices/CourseSlice';
import Select from 'react-select';
import { ProfilePostAsync } from '../redux/slices/PostSlice';

const PostAddtoCourse = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { auth } = useSelector((state) => state.authReducer);
  const [id] = useState(auth?.id);
  const [courseTitle, setCourseTitle] = useState('');
  const [postno, setPostno] = useState('');
  const [post, setPost] = useState('');
  const [postId, setPostId] = useState('');
  const [courseSlug, setCourseSlug] = useState('');
  const { userId, slug } = useParams();
  const { course } = useSelector((state) => state.courseReducer);
  const { profileposts } = useSelector((state) => state.postReducer);

  useEffect(() => {
    if (course && course?.title) {
      setCourseTitle(course?.title);
      setCourseSlug(course?.slug);
    }
  }, [course]);

  useEffect(() => {
    if (auth && userId && parseInt(auth.id) === parseInt(userId)) {
      dispatch(courseShowAsync({ slug, toast }));
      dispatch(ProfilePostAsync({ id, toast }));
    } else {
      toast.error('You are not authorized to edit this course');
      navigate(url.myProfileCourse());
    }
  }, [id, auth, userId, slug, dispatch, navigate]);

  const postAddSubmit = async (e) => {
    e.preventDefault();
    const courseData = new FormData();
    courseData.append('postno', postno);
    courseData.append('postId', postId);
    dispatch(
      postAddtoCourseAsync({ id,courseSlug, courseData, dispatch, navigate, toast })
    );
  };



  const options = profileposts.map((post) => {
    return {
      label: post.title,
      value: post.id,
    };
  });

  const postChange = (selectedOption) => {
    setPost(selectedOption);
    setPostId(selectedOption.value);
  };

  return (
    <section className="form-container">
      <form action="" method="post" encType="multipart/form-data">
        <h3>Add Post In Course</h3>
        <p>
          Course <span>*</span>
        </p>
        <input
          type="text"
          name="course"
          placeholder="Course"
          required
          readOnly
          maxLength="50"
          className="box"
          value={courseTitle}
        />
        <p>
          Post No. <span>*</span>
        </p>
        <input
          type="number"
          name="postno"
          placeholder="Post No"
          required
          maxLength="50"
          className="box"
          value={postno}
          onChange={(e) => setPostno(e.target.value)}
        />
        <p>
          Post <span>*</span>
        </p>
        <div className="box">
        <Select
        value={post}
        onChange={postChange}
        options={options}
      />
        </div>
        <input
          type="submit"
          value="Submit"
          name="submit"
          className="btn"
          onClick={postAddSubmit}
        />
      </form>
    </section>
  );
};

export default PostAddtoCourse;
