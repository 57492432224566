import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { apiFilepath, filepath } from '../helpers/urlConfig';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postAllAsync, setPage } from '../redux/slices/PostSlice';
// import MetaData from '../components/MetaData';
import InfiniteScroll from 'react-infinite-scroll-component';

const Posts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { postLists, pageCount, hasMore, searchKeyword } = useSelector(
    (state) => state.postReducer
  );
  const { auth } = useSelector((state) => state.authReducer);

  // const [title, setTitle] = useState('');
  // const [description, setDescription] = useState('');
  // const [keywords, setKeywords] = useState('');
  // const [author, setAuthor] = useState('');
  // const [image, setImage] = useState('');
  // const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    // setTitle(
    //   'NearToMe: Discover, Share, and Experience Real-Time, Location-Based Images Without AI or Photoshop Alterations.'
    // );
    // setDescription(
    //   `Welcome to Neartome – your gateway to discovering local wonders! Explore images and events near you with our intuitive geo-location feature. Whether it's today or tomorrow, find what's happening around you effortlessly by searching with dates. Join us and embrace the excitement just around the corner!`
    // );
    // setKeywords(
    //   `Near events, Things to do near, Near attractions, Explore near, Near activities, Near sightseeing, Near tourist spots, Near landmarks, Visit near, Near entertainment, Events today, Events this weekend, Events this month, What's happening today, Today's events, This month's events, Upcoming events, Events near today, Today's festivals, Today's activities, Travel Exploration, Local Discovery, Event Sharing, Honest Documentation, Outdoor Adventures, Property Viewing, Food Reviews, Cultural Exploration, Wildlife Observation, Historical Documentation, Emergency Response, Environmental Monitoring, Urban Planning, Retail Transparency, Traffic Updates`
    // );
    // setAuthor('nearToMe, digitolearn');
    // setImage(filepath + '/assets/static/neartome.PNG');
    // setCurrentUrl(filepath);
  }, []);

  useEffect(() => {
    const search = searchKeyword;
    var page = 1;
    dispatch(setPage(page));
    if (auth && auth?.id) {
      const authid = auth?.id;
      dispatch(
        postAllAsync({ authid, page, search, toast, navigate, dispatch })
      );
    } else {
      dispatch(postAllAsync({ page, search, toast, navigate, dispatch }));
    }
  }, [dispatch, navigate, searchKeyword, auth]);

  const nextPage = () => {
    const search = searchKeyword;
    var page = pageCount + 1;
    if (auth && auth?.id) {
      const authid = auth?.id;
      dispatch(
        postAllAsync({ authid, page, search, toast, navigate, dispatch })
      );
    } else {
      dispatch(postAllAsync({ page, search, toast, navigate, dispatch }));
    }
    dispatch(setPage(page));
  };

  const redirectToGoogleMaps = (lat, long) => {
    // Redirect to the Google Maps URL
    const googleMapsUrl = `https://www.google.com/maps/dir//${lat},${long}`;
    // Open the URL in a new tab
    window.open(googleMapsUrl, '_blank');
  };

  return (
    <>
     {/* <MetaData
        title={title}
        description={description}
        keywords={keywords}
        author={author}
        image={image}
        currentUrl={currentUrl}
      />  */}
      <section className="courses">
        <h1 className="heading">All Posts</h1>
        <Link to={`/about`}>
          <p className="btn">
            NearToMe: Discover, Share, and Experience Real-Time, Location-Based
            Images Without AI or Photoshop Alterations. Click to Explore
            NearToMe's Applications!
          </p>
        </Link>
        <InfiniteScroll
          dataLength={postLists.length}
          next={nextPage}
          hasMore={hasMore}
          loader={
            <div style={{ width: '100%' }} className="btn">
              Loading...
            </div>
          }
          endMessage={
            <div style={{ width: '100%' }} className="btn">
              No more posts
            </div>
          }
          scrollThreshold={0.9} // Optional threshold to trigger next load
        >
          <div className="box-container">
            {postLists &&
              postLists.length > 0 &&
              postLists.map((post, i) => (
                <div className="box" key={i}>
                  <div className="tutor">
                    <img
                      src={
                        post?.belongsToUser?.avatar
                          ? apiFilepath +
                            '/uploads/avatar/' +
                            post?.belongsToUser?.avatar
                          : filepath +
                            '/assets/images/company-logo-1658436134701.png'
                      }
                      alt="avatar"
                      onError={(e) => {
                        e.currentTarget.src =
                          filepath + '/assets/images/user.png';
                      }}
                    />
                    <div className="info">
                      <h3>
                        {post?.belongsToUser?.name
                          ? post?.belongsToUser?.name
                          : 'User'}
                      </h3>
                      <span>
                        {post?.utcTime
                          ? moment(post?.utcTime).format(
                              'MMMM Do YYYY, h:mm:ss a'
                            )
                          : null}
                      </span>
                    </div>
                  </div>
                  <Link
                    to={`/post/${post?.slug}`}
                    key={post?.id ? post?.id : i}
                  >
                    <div className="thumb">
                      {post?.fileThumb ? (
                        <img
                          src={
                            apiFilepath +
                            '/uploads/post/thumb/' +
                            post?.fileThumb
                          }
                          alt="post-thumb"
                          onError={(e) => {
                            e.currentTarget.src =
                              filepath + '/assets/static/no-image.jpg';
                          }}
                        />
                      ) : post?.file ? (
                        <img
                          src={apiFilepath + '/uploads/post/' + post?.file}
                          alt="post"
                          onError={(e) => {
                            e.currentTarget.src =
                              filepath + '/assets/static/no-image.jpg';
                          }}
                        />
                      ) : (
                        <img
                          src={filepath + '/assets/static/no-image.jpg'}
                          alt="post"
                          onError={(e) => {
                            e.currentTarget.src =
                              filepath + '/assets/static/no-image.jpg';
                          }}
                        />
                      )}
                      <span>
                        <i className="fas fa-eye"></i>
                      </span>
                    </div>
                  </Link>
                  <div className="tutor">
                    <div className="info">
                      <h3>
                        <i className="fas fa-map-marker-alt"></i> (
                        {post?.latitude}, {post?.longitude}) {post?.address}
                      </h3>
                      <span
                        onClick={() =>
                          redirectToGoogleMaps(post?.latitude, post?.longitude)
                        }
                      >
                        <i className="fas fa-location-arrow"></i> Open Google
                        Map
                        {post?.distance ? ` : ${post?.distance} km  ` : ''}
                        {post?.direction}
                      </span>
                    </div>
                  </div>
                  <h3 className="title">{post?.title ? post?.title : null}</h3>
                </div>
              ))}
          </div>
        </InfiniteScroll>
      </section>
    </>
  );
};

export default Posts;
