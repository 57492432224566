import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as apiUrl from '../../helpers/api';

export const locationAllAsync = createAsyncThunk(
  'location/locationAllAsync',
  async ({ toast }, { rejectedWithValue }) => {
    try {
      const response = await apiUrl.locationAll();
      if (response.data.status) {
        // toast.success(response.data.message);
      } else {
        // toast.error(response.data.message);
      }
      const locationData = response.data.data;
      return { locationData };
    } catch (err) {
      // toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);


export const locationCreateAsync = createAsyncThunk(
  'location/locationCreateAsync',
  async (
    { locationData, dispatch, navigate, toast },
    { rejectedWithValue }
  ) => {
    try {
      const response = await apiUrl.locationCreate(locationData);
      if (response.data.status) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      const locationCreateData = response.data.data;
      return { locationCreateData };
    } catch (err) {
      toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

const locationSlice = createSlice({
  name: 'location',
  initialState: {
    locations: [],
    locationcreate: {},
    error: '',
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // location list
    builder
      .addCase(locationAllAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(locationAllAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.locations = payload.locationData;
        console.log('location fetch sucessfully!');
      })
      .addCase(locationAllAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //   location create 
    builder
      .addCase(locationCreateAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(locationCreateAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.locationcreate = payload.locationCreateData;
        console.log('location created sucessfully!');
      })
      .addCase(locationCreateAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});


export default locationSlice.reducer;
