import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { apiFilepath, filepath } from '../helpers/urlConfig';
import * as url from '../helpers/url';

const MyProfile = () => {
  // var link = '#';
  const { auth } = useSelector((state) => state.authReducer);

  // const deleteAccount = () => {};

  return (
    <section className="user-profile">
      <h1 className="heading">your profile</h1>

      <div className="info">
        <div className="user">
          {auth?.avatar && (
            <img
              src={
                auth?.avatarFullUrl
                  ? auth?.avatar
                  : apiFilepath + '/uploads/avatar/' + auth?.avatar
              }
              alt={auth?.name}
              onError={(e) => {
                e.currentTarget.src = filepath + '/assets/static/user.png';
              }}
            />
          )}
          <h3>{auth && auth.name ? auth.name : 'Name'}</h3>
          <p>{auth && auth.username ? auth.username : 'Username'}</p>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {auth && auth.id && (
              <>
                <Link to={url.myProfileUpdate()} className="inline-btn">
                  Update Profile
                </Link>
                <Link to={url.myPasswordUpdate()} className="inline-btn">
                  Change Password
                </Link>
                {/* <Link to={url.myProfileUpdate()} className="inline-btn">
                  Change Password
                </Link>
                <button className="inline-btn" onClick={deleteAccount}>
                  <i className="fas fa-trash"></i> Delete Account
                </button> */}
              </>
            )}
          </div>
        </div>

        <div className="box-container">
          <div className="box">
            <div className="flex">
              <i className="fas fa-file"></i>
              <div>
                {/* <span>4</span> */}
                <p>My all posts</p>
              </div>
            </div>
            <Link to={url.myProfilePost()} className="inline-btn">
              My Posts
            </Link>
          </div>

          {/* <div className="box">
            <div className="flex">
              <i className="fas fa-cog"></i>
              <div>
                <span>33</span>
                <p>Location & Time update</p>
              </div>
            </div>
            <Link to={url.myProfileLocationUpdate()} className="inline-btn">
              Location & Time 
            </Link>
          </div> */}

          {/* <div className="box">
            <div className="flex">
              <i className="fas fa-heart"></i>
              <div>
                <span>33</span>
                <p>My all courses</p>
              </div>
            </div>
            <Link to={url.myProfileCourse()} className="inline-btn">
              My Courses
            </Link>
          </div> */}

          {/* <div className="box">
            <div className="flex">
              <i className="fas fa-comment"></i>
              <div>
                <span>12</span>
                <p>videos comments</p>
              </div>
            </div>
            <a href={link} className="inline-btn">
              view comments
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default MyProfile;
