import React from 'react';
import { filepath } from '../helpers/urlConfig';
// import { Link } from 'react-router-dom';
// import * as url from '../helpers/url';

const About = () => {
  return (
    <>
      <section className="about">
        <div className="row">
          <div className="image">
            <img
              src={filepath + '/assets/static/neartome.PNG'}
              alt=""
            />
            <img
              src={filepath + '/assets/static/NOCFOLF83536987N.webp'}
              alt=""
            />
            <img
              src={filepath + '/assets/static/companylogo.png'}
              alt=""
            />
             <img
              src={filepath + '/assets/static/companylogo.webp'}
              alt=""
            />
            <img
              src={filepath + '/assets/static/neartome.PNG'}
              alt=""
            />
          </div>

          <div className="content">
            <h3>Welcome to NearToMe</h3>
            <p>
              <b>About Us :</b>NearToMe is a cutting-edge web application
              designed to help you explore and discover images captured in your
              vicinity. Our platform utilizes the latest in geo-location
              technology and camera access to provide you with a unique
              experience. Whether you're a photography enthusiast, a traveler,
              or simply curious about the world around you, NearToMe is your
              gateway to discovering captivating visuals near you.
            </p>
            <h3>NearToMe's Applications :</h3>
            <p>
              <ul>
                <li><b>1. Travel Exploration: </b>Use NearToMe to discover authentic images of destinations in real-time, helping you plan your next adventure.</li>
                <li><b>2. Local Discovery: </b>Explore your surroundings through unaltered images shared by nearby users, uncovering hidden gems and local attractions.</li>
                <li><b>3. Event Sharing: </b>Share real-time images of events happening near you, allowing others to experience the excitement as if they were there.</li>
                <li><b>4. Honest Documentation: </b>Capture and share unedited images of news events, protests, or important moments without any alterations.</li>
                <li><b>5. Outdoor Adventures: </b>Share photos of hiking trails, scenic viewpoints, or outdoor activities to provide accurate representations for fellow outdoor enthusiasts.</li>
                <li><b>6. Property Viewing: </b>Use NearToMe to share unedited images of real estate listings, giving potential buyers an honest depiction of properties.</li>
                <li><b>7. Food and Restaurant Reviews: </b>Share unfiltered images of dishes and dining experiences, helping others make informed decisions about where to eat.</li>
                <li><b>8. Cultural Exploration: </b>Discover authentic images of local culture, traditions, and festivals from different regions around the world.</li>
                <li><b>9. Wildlife Observation: </b>Share real-time images of wildlife sightings, promoting conservation efforts and providing valuable data for researchers.</li>
                <li><b>10. Historical Documentation: </b>Capture and share unaltered images of historical landmarks, preserving their authenticity for future generations.</li>
              </ul>
            </p>
            <h3> How It Works</h3>
            <p>
              <b>Geo-Location Integration : </b>
              NearToMe harnesses the power of geo-location services to pinpoint
              your current position accurately. By accessing your device's
              location services, we can identify where you are in real-time,
              allowing you to explore images captured within your immediate
              vicinity.
            </p>

            <p>
              <b>Camera Access : </b>
              With NearToMe, you can use your device's camera to capture images
              and share them with others in your area. Whether you stumble upon
              a breathtaking sunset or an intriguing landmark, simply snap a
              photo using our built-in camera feature to contribute to the
              ever-growing collection of images available on our platform.
            </p>

            <p>
              <b>Time and Radius Filters : </b>
              Our intuitive interface allows you to customize your image
              exploration experience further. Filter images based on the time
              they were captured to see the latest snapshots from your area.
              Additionally, adjust the radius to narrow down your search and
              focus on images captured within a specific distance from your
              current location.
            </p>
            <h3>Privacy and Security</h3>
            <p>
              At NearToMe, we prioritize the privacy and security of our users.
              Rest assured that your location data and personal information are
              handled with the utmost care and confidentiality. We adhere to
              strict security protocols to safeguard your data and ensure a safe
              browsing experience for all users.
            </p>
            <h3>Get Started</h3>
            <p>
              Ready to embark on a visual journey? Simply grant NearToMe access
              to your device's location and camera, and start exploring the
              world around you through captivating images captured by fellow
              users. Whether you're looking for inspiration, seeking hidden gems
              in your neighborhood, or simply curious about what's out there,
              NearToMe is your ultimate companion.
            </p>
            <p>
              Join our community today and discover the beauty that surrounds
              you with NearToMe!
            </p>
            {/* <a href="courses.html" className="inline-btn">
              our courses
            </a> */}
          </div>
        </div>
        <div className="box-container">
          {/* <Link to={url.courses()}>
            <div className="box">
              <i className="fas fa-graduation-cap"></i>
              <div>
                <h3>+10k</h3>
                <p>Our courses</p>
              </div>
            </div>
          </Link>

          <Link to={url.home()}>
            <div className="box">
              <i className="fas fa-sticky-note"></i>
              <div>
                <h3>+40k</h3>
                <p>Our Posts</p>
              </div>
            </div>
          </Link>
          <Link to={url.teachers()}>
            <div className="box">
              <i className="fas fa-chalkboard-user"></i>
              <div>
                <h3>+2k</h3>
                <p>Our Teachers</p>
              </div>
            </div>
          </Link> */}
          {/* <div className="box">
            <i className="fas fa-briefcase"></i>
            <div>
              <h3>100%</h3>
              <p>job placement</p>
            </div>
          </div> */}
        </div>
      </section>

      {/* <section className="reviews">
        <h1 className="heading">student's reviews</h1>

        <div className="box-container">
          <div className="box">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur.
              Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero
              facilis fuga. Eligendi eaque molestiae modi?
            </p>
            <div className="student">
              <img src={filepath + '/assets/images/pic-2.jpg'} alt="" />
              <div>
                <h3>john deo</h3>
                <div className="stars">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star-half-alt"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur.
              Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero
              facilis fuga. Eligendi eaque molestiae modi?
            </p>
            <div className="student">
              <img src={filepath + '/assets/images/pic-3.jpg'} alt="" />
              <div>
                <h3>john deo</h3>
                <div className="stars">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star-half-alt"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur.
              Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero
              facilis fuga. Eligendi eaque molestiae modi?
            </p>
            <div className="student">
              <img src={filepath + '/assets/images/pic-4.jpg'} alt="" />
              <div>
                <h3>john deo</h3>
                <div className="stars">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star-half-alt"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur.
              Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero
              facilis fuga. Eligendi eaque molestiae modi?
            </p>
            <div className="student">
              <img src={filepath + '/assets/images/pic-5.jpg'} alt="" />
              <div>
                <h3>john deo</h3>
                <div className="stars">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star-half-alt"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur.
              Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero
              facilis fuga. Eligendi eaque molestiae modi?
            </p>
            <div className="student">
              <img src={filepath + '/assets/images/pic-6.jpg'} alt="" />
              <div>
                <h3>john deo</h3>
                <div className="stars">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star-half-alt"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur.
              Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero
              facilis fuga. Eligendi eaque molestiae modi?
            </p>
            <div className="student">
              <img src={filepath + '/assets/images/pic-7.jpg'} alt="" />
              <div>
                <h3>john deo</h3>
                <div className="stars">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star-half-alt"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default About;
